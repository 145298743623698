<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <div class="w-100 justify-content-center h-100">
      <div class="error-wrap">
        <div class="row w-100">
          <div class="col-md-6">
            <h1>{{ $t("notFoundErr.title") }}</h1>
            <p class="sub-title">
              {{ $t("notFoundErr.subtitle") }}
            </p>
            <p class="sm-text">
              {{ $t("notFoundErr.desc") }}
            </p>
            <CButton color="info" @click="navigateToHome()">{{
              $t("notFoundErr.go")
            }}</CButton>
          </div>
          <div class="col-md-6"></div>
        </div>
      </div>
    </div>
  </CContainer>
</template>

<script>
import { addClassToBody, removeClassBody } from "./../../util/util";
import { mapGetters } from "vuex";

import { navigate } from "./../../router/index";
import { PATHNAME, COMPONENT_NAME } from "./../../util/constants";
export default {
  name: "Page404",
  methods: {
    navigateToHome() {
      if (this.ADMIN_SIGNIN_RES) {
        navigate(PATHNAME.SYSTEM_LOGIN);
      } else {
        navigate(PATHNAME.AGENT_LOGIN);
      }
    },
  },
  computed: {
    ...mapGetters("sSysLogin", {
      ADMIN_SIGNIN_RES: "GET_ADMIN_SIGNIN_RES",
    }),
  },
  beforeCreate() {
    addClassToBody(COMPONENT_NAME.PAGE_404);
  },

  beforeDestroy() {
    removeClassBody();
  },
};
</script>
